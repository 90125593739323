export const serverStatus = "Live";

export const serverLink =
  serverStatus === "Dev"
    ? "http://localhost:4480/"
    // ? "http://192.168.1.73:4480/"
    :
    // "https://smartsourcingapi.com:5001/" // lux mundi

    // "https://smartsourcingapi.com:5002/"  //babaahmad

    "https://smartsourcingapi.com:5003/"  // alansar

//"https://smartsourcingapi.com:5004/"   //olivia

// "https://smartsourcingapi.com:5005/"   //cosmopolitan



// export const projectName = "Baba Ahmed University";
// export const projectCode = "BABA_AHMED_UNIVERSITY";
// export const projectLogo = "https://babaahmeduniversity.edu.ng/logo.png";
// export const projectNameOfficialEmail = "info@babaahmeduniversity.edu.ng";

// export const projectName = "Olivia University";
// export const projectCode = "OLIVIA_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://oliviauniversity.com/logo.png";
// export const projectNameOfficialEmail = "info@oliviauniversity.com";


export const projectName = "Al-Ansar University";
export const projectCode = "BABA_AHMED_UNIVERSITY";
export const projectLogo = "https://aum.edu.ng/logo.png";
export const projectNameOfficialEmail = "aum.edu.ng";


// export const projectName = "Lux Mundi University";
// export const projectCode = "LUX_MUNDI_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://luxmundi.edu.ng/logo.png";
// export const projectNameOfficialEmail = "info@luxmundiuniversity.edu.ng";


// export const projectName = "Cosmopolitan University | Student Portal";
// export const projectCode = "COSMOPOLITAN_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://cosmopolitan.edu.ng/logo.png";
// export const projectNameOfficialEmail = "info@cosmopolitan.edu.ng";
