import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails, setPermissionDetails } from "../../../actions/setactiondetails";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import {decryptData} from "../../../resources/constants";
import { useNavigate } from "react-router";

function LoginFromStaff(props) {
  const navigate = useNavigate();
  const _id = window.location.href.split('/')[4];
  const  lastindex = decryptData(_id)

  //const lastindex = decryptData(_id).split("/")[3];

  const getData = async () => {
    await axios.post(`${serverLink}login/get/student/password`, { studentID: lastindex }).then(async (result) => {
      if (result.data.length > 0) {
        const sendData = {
          username: result.data[0]?.StudentId,
          password: result.data[0]?.Password,
        };
        axios
          .post(`${serverLink}login/student/login`, sendData)
          .then((result) => {
            if (result.data.message === "success") {
              props.setOnLoginDetails(result.data.userData);
              setTimeout(() => {
                window.location.href = '/dashboard';
              }, 500);
            }
          })

      } else {
        props.setOnLoginDetails([]);
        props.setOnPermissionDetails([]);
        navigate('/login')
      }
    })
  }

  useEffect(() => {
    getData();
  }, [""])


  return (
    <div>

    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
    setOnPermissionDetails: (p) => {
      dispatch(setPermissionDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFromStaff);
